import React from 'react';
import './ContactUsPage.css';

type ContactUsPageProps = {
  setActivePage: any;
  activePage: string;
}

export class ContactUsPage extends React.Component<ContactUsPageProps, {}> {
  render() {
    if (this.props.activePage !== 'contact-us') {
      return (<div></div>);
    }

    return (
      <div className="layout-page" id="contact-us">
        <div className="layout-contact-us">
          <h1>Contact Us</h1>
          <p>
          <strong>AMERICA’S CONCENTRATION CAMPS</strong> is a Visual Communications production.
          </p>
          <p>Questions or comments? Feel free to contact us below:</p>
          <p>
          Visual Communications<br />
          120 Judge John Aiso St.<br />
          Los Angeles, CA 90012<br />
          <a href="tel:+12136804462">213-680-4462</a>
          </p>
          <p><a href="mailto:archives@vcmedia.org">archives@vcmedia.org</a></p>
          <p style={ { paddingTop: '1em' } }>
          <a className="button-link" onClick={ () => this.props.setActivePage('app') } >Return to Exhibit</a>
          </p>
        </div>
      </div>
    );
  }
}