import React from 'react';
import {values} from 'lodash';
import './Layout.css';
import {ExhibitImage, ExhibitImageGalleryItem} from '../ExhibitImage/ExhibitImage';
import data from '../../assets/data.json';

export type LayoutProps = {
  handleModalOverlay: any;
  setActivePage: any;
}

export type LayoutState = {
  galleryItems: ExhibitImageGalleryItem[]
}

export class DesktopLayout extends React.Component<LayoutProps, LayoutState> {

  private galleryIndex: number;

  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      galleryItems: values(data.gallery)
    };
    this.galleryIndex = 0;
  }

  handleShowModal = (galleryItem: ExhibitImageGalleryItem) => {
    this.props.handleModalOverlay(galleryItem);
  };

  getRow = (count: number) => {
    let rows = [];
    // Workaround since setState is a little finnicky right now
    if (this.galleryIndex >= this.state.galleryItems.length) {
      this.galleryIndex = 0;
    }
    for (let i=0; i<count; i++){
      const galleryItem = this.state.galleryItems[this.galleryIndex] as ExhibitImageGalleryItem;
      rows.push(<ExhibitImage galleryItem={ galleryItem } showModal={ this.handleShowModal } />);
      this.galleryIndex++;
    }
    return rows;
  };

  render() {
    return (
      <div className="layout-container layout-desktop">
        <div className="row full-row">
          {this.getRow(12)}
        </div>
        <div className="row full-row">
          {this.getRow(12)}
        </div>
        <div className="row info-row">
          <div className="inner-box">
            <div className="row">
              {this.getRow(2)}
            </div>
            <div className="row">
              {this.getRow(2)}
            </div>
            <div className="row">
              {this.getRow(2)}
            </div>
            <div className="row">
              {this.getRow(2)}
            </div>
          </div>
          <div className="info-box">
            <div className="info-content">
              <h1>America's Concentration Camps,</h1>
              <h1 className="revisited"><i>Revisited</i></h1>
              <div className="info-button-container">
                <a href="#" onClick={ () => { this.props.setActivePage('about') }}><span>About</span></a>
                <a href="/assets/2022_ACC_TeachersActivityGuide.pdf" target="_blank"><span>Activity Guide</span></a>
              </div>
            </div>
          </div>
          <div className="inner-box">
            <div className="row">
              {this.getRow(2)}
            </div>
            <div className="row">
              {this.getRow(2)}
            </div>
            <div className="row">
              {this.getRow(2)}
            </div>
            <div className="row">
              {this.getRow(2)}
            </div>
          </div>
        </div>
        <div className="row full-row">
          {this.getRow(12)}
        </div>
        <div className="row full-row">
          {this.getRow(12)}
        </div>
      </div>
    )
  }
}