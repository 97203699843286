import React, { Component } from 'react';

type ExhibitImageProps = {
  galleryItem: ExhibitImageGalleryItem,
  showModal: any;
}

export type ExhibitImageGalleryItem = {
  id?: string;
  title: string;
  caption: string;
  reference?: string;
  url?: string;
  completed_reference?: string;
  images: {
    original?: string;
    thumbnail: string;
  },
  references?: ExhibitImageGalleryItemLink[],
  sources?: ExhibitImageGalleryItemLink[]
};

type ExhibitImageGalleryItemLink = {
  url?: string;
  caption: string;
};

export class ExhibitImage extends React.Component<ExhibitImageProps, {}> {
  handleLocalImageCellClick = () => {
    this.props.showModal(this.props.galleryItem);
  }

  render() {
    return (
      <div className="exhibit-image image-cell" onClick={ this.handleLocalImageCellClick } style={{
        backgroundImage: `url(${this.props.galleryItem.images.thumbnail})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%'
      }}>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="></img>
      </div>
    )
  }
}