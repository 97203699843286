import React from 'react';
import './App.css';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { DesktopLayout } from './components/Layout/DesktopLayout';
import { InfoModal } from './components/InfoModal/InfoModal';
import { ExhibitImageGalleryItem } from './components/ExhibitImage/ExhibitImage';
import { MobileLayout } from "./components/Layout/MobileLayout";
import { AboutPage } from './components/AboutPage/AboutPage';
import { ContactUsPage } from './components/ContactUsPage/ContactUsPage';

type AppState = {
  lastSelectedGalleryItem: ExhibitImageGalleryItem | null;
  activePage: string;
};

export default class App extends React.Component<{}, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      lastSelectedGalleryItem: null,
      activePage: 'app'
    }
  }

  setActivePage = (page: string) => {
    this.setState({ activePage: page });
  }
  
  handleModalOverlay = (galleryItem: ExhibitImageGalleryItem) => {
    this.setState({ lastSelectedGalleryItem: galleryItem });
  };

  resetModalOverlay = () => {
    this.setState({ lastSelectedGalleryItem: null });
  };

  render() {
    const layout = (this.state.activePage === 'app') ?
      <div id="layout-master">
        <DesktopLayout handleModalOverlay={ this.handleModalOverlay } setActivePage={ this.setActivePage } />
        <MobileLayout handleModalOverlay={ this.handleModalOverlay } setActivePage={ this.setActivePage }/>
      </div> : <div></div>;

    return (
      <div className="App">
        <div className="screen">
          <Header setActivePage={ this.setActivePage } />

          { layout }

          <AboutPage setActivePage={ this.setActivePage } activePage={ this.state.activePage } />
          <ContactUsPage setActivePage={ this.setActivePage } activePage={ this.state.activePage } />
        </div>
        <InfoModal galleryItem={ this.state.lastSelectedGalleryItem } handleModalClose={ this.resetModalOverlay }/>
        <Footer />
      </div>
    );
  }
}