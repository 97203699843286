import React from "react";
import './Footer.css';

export class Footer extends React.Component {
  render() {
    const year = (new Date()).getFullYear();
    const heartsStyle = { color: "#08b3e2" };
    return (
      <footer>
        <div className="logo-box">
          <img src="/assets/Aratani_Foundation_Logo.png" alt="Aratani Foundation" />
          <a href="https://calhum.org/" target="_blank" rel="noopener noreferrer" aria-label="Open window for California Humanities"><img src="/assets/CAHumanitiesLogo.png" alt="Sponsored by California Humanities" /></a>
          <a href="https://www.library.ca.gov/" target="_blank" rel="noopener noreferrer" aria-label="Open window for California State Library"><img src="/assets/CSL_Logo.jpg" alt="Sponsored by California State Library" /></a>
          <a href="https://mellon.org/" target="_blank" rel="noopener noreferrer" aria-label="Open window for Mellon Foundation"><img src="/assets/MellonLogo.png" alt="Sponsored by Mellon Foundation" /></a>
          <a href="https://www.neh.gov/" target="_blank" rel="noopener noreferrer" aria-label="Open window for National Endowment for the Humanities"><img src="/assets/NEH-Preferred-Seal.png" alt="Sponsored by National Endowment for the Humanities" /></a>
        </div>
        <div>
          <strong>Visual Communications</strong> &bull; Connecting Communities Through Media Arts  &copy; { year }. All rights reserved.<br/>
          <a href="https://www.vcmedia.org" target="_blank" rel="noopener noreferrer">www.vcmedia.org</a> | 213.680.4462
        </div>
        <div>
          Made with <span style={ heartsStyle }>&hearts;</span> in Los Angeles by <a href="https://whatis.media/" target="_blank" rel="noopener noreferrer"><strong>What Is Media Even?</strong></a>.
        </div>
      </footer>
      );
    }
  }
