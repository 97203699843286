import React from 'react';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import './Header.css';

type HeaderProps = {
  setActivePage: any;
}

export class Header extends React.Component<HeaderProps, {}> {
  render() {
    return (
      <header className="camps-header">
        <BurgerMenu setActivePage={ this.props.setActivePage } />
        <div className="middle">
          <div className="logo">
            <a onClick={ () => { this.props.setActivePage('app') }}><img src="/assets/vc-long-logo.png" aria-label="Visual Communications" /></a>
          </div>
        </div>
        <div className="right"></div>
      </header>
    );
  }
}
