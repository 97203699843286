import React from "react";
import './BurgerMenu.css';

type BurgerMenuProps = {
    setActivePage: any;
  }

type BurgerMenuState = {
    isSlideOut: boolean;
};

export class BurgerMenu extends React.Component<BurgerMenuProps, BurgerMenuState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isSlideOut: false
        };
    }

    setActivePage = (page: string) => {
        this.toggleSlideOut();
        this.props.setActivePage(page);
    };

    toggleSlideOut = () => {
        this.setState({ isSlideOut: !this.state.isSlideOut })
    };

    render() {
        const menuClasses = `menu${ this.state.isSlideOut ? ' slideout' : ''}`;
        return (
            <div className="menu-container">
                <div className="menu-icon" onClick={ this.toggleSlideOut }>
                    <i className="fa fa-bars" /> <span className="menu-icon-text">Menu</span>
                </div>
                <div className={ menuClasses }>
                    <div className="menu-close-container">
                        <span className="menu-close" onClick={ this.toggleSlideOut }><i className="fa fa-close"></i> Close</span>
                    </div>
                    <ul className="burger-menu-list">
                        <li className="burger-menu-list-item" onClick={ () => { this.setActivePage('app') } }>Home</li>
                        <li className="burger-menu-list-item" onClick={ () => { this.setActivePage('about') } }>About</li>
                        <li className="burger-menu-list-item" onClick={ () => window.open('/assets/2022_ACC_TeachersActivityGuide.pdf') }>Activity Guide</li>
                        <li className="burger-menu-list-item" onClick={ () => window.open('/assets/2022_ACC_01__Replay_Zine.pdf') }>&ldquo;<em>Replay</em>&rdquo; Zine</li>
                        <li className="burger-menu-list-item" onClick={ () => window.open('/assets/2022_ACC_02__Resilience_Zine.pdf') }>&ldquo;<em>Resilience</em>&rdquo; Zine</li>
                        <li className="burger-menu-list-item" onClick={ () => { this.setActivePage('contact-us') } }>Contact Us</li>
                    </ul>
                </div>
            </div>
        );
    }
};