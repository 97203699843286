import React from 'react';
import './InfoModal.css';
import { ExhibitImageGalleryItem } from '../ExhibitImage/ExhibitImage';
import { isNull } from 'lodash';

type InfoModalState = {
  showModal: boolean;
}

type InfoModalProps = {
  galleryItem: ExhibitImageGalleryItem | null;
  handleModalClose: Function;
}

export class InfoModal extends React.Component<InfoModalProps, InfoModalState> {

  private comp: any;

  constructor(props: any) {
    super(props);
    this.state = {
      showModal: true
    };
  }

  handleCloseModal = () => {
    document.removeEventListener('keydown', this.escapeKeyCloseModal.bind(this));
    this.comp.removeEventListener('click', this.clickOutsideModal.bind(this));
    this.setState({ showModal: false });
    this.props.handleModalClose();
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.galleryItem !== null && (this.props.galleryItem !== prevProps.galleryItem)) {
      this.setState({ showModal: true });
      document.addEventListener('keydown', this.escapeKeyCloseModal.bind(this));
      this.comp.addEventListener('click', this.clickOutsideModal.bind(this));
    }
  }

  escapeKeyCloseModal (event: any){
    if(event.keyCode === 27 && this.state.showModal) {
      this.handleCloseModal();
    }
  }

  clickOutsideModal (event: any) {
    if (event.target === this.comp) {
      this.handleCloseModal();
    }
  }

  render() {
    if (isNull(this.props.galleryItem)) {
      return '';
    } else {
      return (
        <div ref={elem => this.comp = elem} className={ this.state.showModal ? 'overlay-modal overlay-modal-show' : 'overlay-modal overlay-modal-hide'}>
          <div className="modal-outer-container">
            <div className="modal-body-container">
              <div className="modal-left">
                <div className="modal-close-button show-mobile" aria-hidden="true" onClick={ this.handleCloseModal }>
                  <i className="fa fa-times"></i> Close
                </div>
                <img src={ this.props.galleryItem.images.original } title={ this.props.galleryItem.title } />
              </div>
              <div className="modal-right">
                <div className="modal-close-button show-desktop" aria-hidden="true" onClick={ this.handleCloseModal }>
                  <i className="fa fa-times"></i> Close
                </div>
                <div className="modal-body" dangerouslySetInnerHTML={{ __html: this.props.galleryItem.caption }}>
                </div>
                <div className="modal-quote">
                  <ReferenceBlock galleryItem={ this.props.galleryItem } />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export class ReferenceBlock extends React.Component<{ galleryItem: ExhibitImageGalleryItem }, {}> {
  render() {
    if (isNull(this.props.galleryItem) || (!this.props.galleryItem.references && !this.props.galleryItem.sources)) {
      return '';
    }

    const block = [];

    if (this.props.galleryItem.sources) {
      const sources = this.props.galleryItem.sources.map((source) => {
        if (source.url) {
          return (<li><a className="external" href={ source.url } target="_blank" rel="noopener noreferrer">{ source.caption }</a></li>);
        } else {
          return (<li>{ source.caption }</li>);
        }
      });

      block.push((
        <blockquote className="modal-sources">
          <ul>
            { sources }
          </ul>
        </blockquote>
      ));
    }

    if (this.props.galleryItem.references) {
      const references = this.props.galleryItem.references.map((reference) => {
        if (reference.url) {
          return (<li><a className="external" href={ reference.url } target="_blank" rel="noopener noreferrer">{ reference.caption }</a></li>);
        } else {
          return (<li>{ reference.caption }</li>);
        }
      });

      block.push((
        <blockquote className="modal-references">
          <ul>
            { references }
          </ul>
        </blockquote>
      ));
    }

    return block;
  }
}
