import React from 'react';
import './AboutPage.css';

type AboutPageProps = {
  setActivePage: any;
  activePage: string;
}

export class AboutPage extends React.Component<AboutPageProps, {}> {
  render() {
    if (this.props.activePage !== 'about') {
      return (<div></div>);
    }

    return (
      <div className="layout-page" id="about">
        <div className="layout-about">
            <a onClick={ () => this.props.setActivePage('app') } >&laquo; Return to Exhibit</a>
            <h1>AMERICA&rsquo;S CONCENTRATION CAMPS: An Introduction</h1>
            <p><img src="/assets/2019_1016_los angeles little_tokyo_at_first_light_exhibit_layout_a.ferrer_0151.jpg" aria-label="At First Light exhibit in Little Tokyo" /></p>
            <p>
              In 1970, when Bob Suzuki from the Japanese American Citizens League (JACL) approached UCLA film school student and photographer Robert A. Nakamura to create a modest photo display of World War II Japanese American internment camp pictures, little did the JACL know that Robert envisioned an opportunity far more expansive than the six to ten mounted pictures expected from a budget of $100. 
            </p>
            <p>
              Robert worked with his brother Norm Nakamura, UCLA visual arts student Alan Ohashi, art professor Kaz Higa, and activists John Ito and Tracy Okida to deliver a solution that far exceeded the JACL&rsquo;s wildest expectations. They scored a generous deal on lightweight lumber and hardware supplies, skillfully combined images from a special UCLA Library collection (The Japanese American Relocation Project/JARP), pulled quotes from various historical and research sources, and used their own skills at darkroom photography and photo-finishing to create a modular, mobile solution that could be arranged in differing spaces and environments.
            </p>
            <p>
              The resulting photo sculpture, AMERICA&rsquo;S CONCENTRATION CAMPS, demonstrated the impact of creativity and financial resourcefulness that became the working ethos of <a href="https://vcmedia.org" target="_blank"><strong>Visual Communications</strong></a> (VC), the non-profit arts organization that Robert A. Nakamura, Alan Ohashi, and fellow UCLA film school students Duane Kubo and Eddie Wong formed as a direct outcome of the JACL confinement site project.
            </p>
            <p>
              Currently, much is known about the mass incarceration of people of Japanese ancestry, many of who were American citizens, during World War II. Legal and historical aspects have been studied. Film, plays, and other artistic pieces have explored its significance and lessons. Recent civil liberty injustices similar to World War II mass incarceration include family detentions at the United States&rsquo; southern border, and Executive Order 13769, which temporarily banned the travel of people from primarily Islam-practicing countries.
            </p>
            <p>
              However, in the late 1960s, the incarceration of people of Japanese descent was effectively unknown. The fact that America had concentration camps of its own was forgotten and their former sites laid abandoned. However, their rediscovery by newly empowered Asian American activists was a defining moment in Asian American — and American — history, and led to the birth of Visual Communications.
            </p>
            <p>
              AMERICA&rsquo;S CONCENTRATION CAMPS, created in 1970 for the Japanese American Citizens League&rsquo;s Campaign to Repeal Title II of the Internal Security Act of 1950, which gave the President authority to detain people who were simply assumed to be security risks, was more than a production of Visual Communications; it is also the origin story of Visual Communications.
            </p>
            <p>
              At least two copies of the AMERICA&rsquo;S CONCENTRATION CAMPS cubes exhibit were produced at the commission of the JACL. The nascent Visual Communications collective, at first a special student committee of the JACL&rsquo;s Pacific Southwest District chapter before gaining non-profit autonomy in April 1971, retained a duplicate copy to arrange exhibition presentations at various community venues and events, while the original copies travelled to numerous JACL chapter functions, introducing multiple generations of Japanese Americans, as well as other communities of all ethnicities and social and economic standing, to the stark realities of the Japanese American confinement site experience.
            </p>
            <p>
              <div><img src="/assets/bob_cubes.jpg" aria-label="Robert Nakamura at the Cubes Exhibit at UCLA" alt="Robert Nakamura at the Cubes Exhibit at UCLA"/></div>
              <blockquote style={{ margin: "1em" }}>
                May 11, 1972: Visual Communications&rsquo; mobile photographic exhibit AMERICA&rsquo;S CONCENTRATION CAMPS is the centerpiece of a larger display identifying contrasting media distortions of Asian Americans. In this self-portrait, creator Robert Nakamura poses with the &ldquo;Cubes Exhibit&rdquo; and accompanying display in UCLA&rsquo;s Rolfe Hall. <em>(Photo: Robert Nakamura/Visual Communications Photographic Archive - George T. Ishizuka and Harukichi Nakamura Asian American Movement Collection)</em>
              </blockquote>
            </p>
            <p>
              As Visual Communications transitioned from a filmmakers&rsquo; collective to a full-service community-based media arts center, the &ldquo;Cubes Exhibit&rdquo;, as AMERICA&rsquo;S CONCENTRATION CAMPS became popularly known, increasingly fulfilled a crucial educational and advocacy role. The story of Japanese American relocation and internment developed into a full-blown campaign for monetary redress for the over 120,000 people whose lives were affected by the WWII confinement site experience.
            </p>
            <p>
            At numerous student conferences, gallery exhibitions, and community engagement &ldquo;flashpoints,&rdquo; the stories of relocation and internment that were unlocked through the &ldquo;Cubes Exhibit&rdquo; fueled both the JACL&rsquo;s legislative initiatives to seek justice for those whose lives were affected by Executive Order 9066, which authorized  the forced removal of Japanese Americans from the West Coast, and the emergence of grassroots efforts such as the Los Angeles Committee on Redress and Reparations — the forerunner of Nikkei for Civil Rights &amp; Redress (NCRR).
            </p>
            <p>
              While the JACL&rsquo;s copies of the “Cubes Exhibit” were retired some time ago and sit in storage, Visual Communications continues to circulate AMERICA&rsquo;S CONCENTRATION CAMPS to select community events, most recently at the 2019 exhibit <a href="https://vcmedia.org/atfirstlight" target="_blank"><em><strong>At First Light: The Dawning of Asian Pacific America</strong></em></a> at the Japanese American National Museum.
            </p>
            <p>
              Five decades later, it is a marvel to see the effect that VC&rsquo;s first-ever production has on newer generations of students and activists every time visitors encounter the &ldquo;Cubes Exhibit&rdquo; for the first time. The lessons revealed through this pioneering work resonate today, in a day and age not unlike the 1960s
            </p>
            <p>
              To ensure that these lessons are perpetuated, this online exhibit reproduces the images from the original photo sculpture and foregrounds key updates to bring the comparisons between Japanese American confinement and modern-day instances of xenophobia and racism. Take a look at the student-produced zines that employ select &ldquo;Cubes Exhibit&rdquo; images, and an Education Guide that can be used in classrooms.
            </p>
            <p>Through these updates, we expect the legacy of AMERICA&rsquo;S CONCENTRATION CAMPS to be a lasting one.</p>

            <p><em>&mdash; Abraham Ferrer, with Karen Ishizuka</em></p>

            <p style={ { paddingTop: '2em', textAlign: 'center' }}><a className="button-link" onClick={ () => this.props.setActivePage('app') } >Return to Exhibit</a></p>
        </div>
      </div>
    );
  }
}